import type { DefaultSeoProps, NextSeoProps } from "next-seo";

import { APP_NAME, SEO_IMG_DEFAULT, SEO_IMG_OGIMG, WEBSITE_URL } from "@calcom/lib/constants";

export type HeadSeoProps = {
  title: string;
  description: string;
  siteName?: string;
  name?: string;
  url?: string;
  username?: string;
  canonical?: string;
  nextSeoProps?: NextSeoProps;
};

const seoImages = {
  default: SEO_IMG_DEFAULT,
  ogImage: SEO_IMG_OGIMG,
};

export const getSeoImage = (key: keyof typeof seoImages): string => {
  return seoImages[key];
};

export const seoConfig: {
  headSeo: Required<Pick<HeadSeoProps, "siteName">>;
  defaultNextSeo: DefaultSeoProps;
} = {
  headSeo: {
    siteName: APP_NAME,
  },
  defaultNextSeo: {
    title: "FanClub - Discover and Meet with Content Creators ",
    description:
      "FanClub is the premier Creator-centric social media platform where you can connect with content creators and other online experts. Book time and meet with Content Creators. Create and share memorable moments with Creators on the FanClub app.",
    openGraph: {
      type: "website",
      url: "https://fanclub.app/",
      title: "FanClub - Discover and Meet with Content Creators ",
      description:
        "FanClub is the premier Creator-centric social media platform where you can connect with content creators and other online experts. Book time and meet with Content Creators. Create and share memorable moments with Creators on the FanClub app.",
      images: [
        {
          url: `${WEBSITE_URL}/og-image.png`,
          alt: "FanClub",
        },
      ],
    },
    twitter: {
      handle: "@FanClubapp_",
      site: "@FanClubapp_",
      cardType: "summary_large_image",
    },
  },
} as const;
